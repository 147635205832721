::-webkit-scrollbar {
  display: none;
}

.hide {
  display: none;
}
/* Initial state */
.heightTran {
  /* animation: max-height 1s ease; */
  transition: max-height 1s ease;
  overflow: hidden;
}

.heightTran.expanded {
  max-height: max-content;
}

.effect {
  transition: all 0.5s ease;
}

.fadeEffect {
  transition: opacity 0.5s ease;
}

.heightTransition {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.heightTransition {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

/* Final state */
.heightTransition.active {
  height: auto;
  max-height: 200px; /* Adjust this value to your desired final height */
  opacity: 1;
  overflow-y: scroll;
}

.heightTransition.active::-webkit-scrollbar {
  display: block;
  background-color: bisque;
  width: 5px;
  border-radius: 5px;
}

.heightTransition.active::-webkit-scrollbar-thumb {
  display: block;
  background-color: rgb(90, 58, 19);
  border-radius: 5px;
}

.scrollerRed::-webkit-scrollbar {
  display: block;
  background-color: rgb(215, 215, 215);
  width: 5px;
  border-radius: 5px;
}

.scrollerRed::-webkit-scrollbar-thumb {
  display: block;
  background-color: #ef4444;
  border-radius: 5px;
}

/* Optional: Add transition for other properties */
.heightTransitionV2 {
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

/* Optional: Add a different animation direction */
.heightTransitionV2.active {
  max-height: 0;
  opacity: 0;
}

.backDrop {
  /* filter: drop-shadow(0px 0px 1px rgba(255, 255, 255)); */
  filter: invert();
}


.notistack-SnackbarContainer,
.go4034260886 {
  /* top: 5% !important;
  right: 10% !important;
  transform: translateX(50%) !important;
  flex-direction: column !important; */
}

.MuiPaper-root.snackbar {
  background: transparent;
  box-shadow: none;
  > .container {
    border-radius: 5rem;
    background: linear-gradient(109.73deg, #3b82f6 11.18%, #243580 85.77%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    grid-gap: 1rem;
    color: white;
    > svg {
      color: white;
      font-size: 1.8rem;
    }
    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.02em;
      text-align: center;
    }
    > button {
      color: white;
      padding: 0;
    }
  }
}