.MuiAutocomplete-root{
  width: 100% !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon{
  margin-top: 0px !important;
}
.MuiFormLabel-root.MuiInputLabel-root{
  display: none;
}
.css-14lo706{
  display: none !important;
}
.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none !important;
}
.MuiFormControl-root.MuiFormControl-fullWidth{
  /* padding: 5% !important; */
  border: none !important;
  outline: none !important;
  > div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth{
    padding: 0.1rem !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 0.75rem;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
  border: none !important;
  outline: none !important;
}