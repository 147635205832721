.otpContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.otpContainer>div{
  width: 100%;
  justify-content: space-between;
}

.otpContainer>div>input{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.otp_code_container{
	display: flex;
	align-items: center;
	justify-content: center;
}

.EditNameDiv .nameEdit{
  text-decoration-line: underline;
}

input[type=number]:hover {
    -moz-appearance: textfield !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}